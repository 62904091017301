import React from 'react';
import VisionComponent from '../component/Company/visionComponent';
import { graphql } from 'gatsby';
import MissionComponent from '../component/Company/missionComponent';
import TechnicalComponent from '../component/SalesForce/technichalComponent';
import CantactSalesForceContact from '../component/SalesForce/contactSalesForceComponent';
import FooterComponent from '../component/footerComponent';
import ServicesHomeComponent from '../component/Services/servicesHomeComponent';
import SalesforceImage from '../images/sales_force_banner-min.jpg';
import Layout from '../component/layout';
const SalesForce = props => {
  const {
    data: {
      wpPage: {
        seo,
        homePageHeaderSection: { homeHeaderTitle, homeHeaderDescription },
        companyVisionSection: {
          companyVisionDescription,
          companyVisionTitle,
          companyVisionImage: {
            localFile: {
              childImageSharp: { gatsbyImageData: companyVisionImage },
            },
          },
        },
        companyMissionSection: {
          companyMissionDescription,
          companyMissionTitle,
          companyMissionImage: {
            localFile: {
              childImageSharp: { gatsbyImageData: companyMissionImage },
            },
          },
        },
        technicalSection: {
          technicalSectionDescription,
          technicalSectionListItem,
          technicalSectionListTitle,
          technicalSectionSubtitle,
          technicalSectionTitle,
          technicalSectionImage: {
            localFile: {
              childImageSharp: { gatsbyImageData: technicalSectionImage },
            },
          },
        },
      },
    },
    pageContext: { title },
  } = props;
  return (
    <Layout seo={seo} title={title}>
      <ServicesHomeComponent
        servicesBackground={SalesforceImage}
        servicesHomeTitle={homeHeaderTitle}
        servicesDescription={homeHeaderDescription}
      />
      <VisionComponent
        visionTitle={companyVisionTitle}
        visionDescription={companyVisionDescription}
        VisionImage={companyVisionImage}
        isSalesForce={true}
      />
      <MissionComponent
        visionTitle={companyMissionTitle}
        missionDescription={companyMissionDescription}
        imageMission={companyMissionImage}
      />
      <TechnicalComponent
        technicalTitle={technicalSectionTitle}
        technicalSubtitle={technicalSectionSubtitle}
        technicalImage={technicalSectionImage}
        technicalDescription={technicalSectionDescription}
        technicalListTitle={technicalSectionListTitle}
        technicalListItem={technicalSectionListItem}
      />
      <CantactSalesForceContact
        joinTitle='Let’s Solve Your Salesforce Challenges!'
        joinDescription='Uptech’s Sh.P.K  team of Salesforce wizards is ready to take on your Salesforce technical support burden.'
        joinButton='Contact us'
        linkSalesforce='/contact'
      />
      <FooterComponent />
    </Layout>
  );
};
export const query = graphql`
  query {
    wpPage(slug: { eq: "salesforce" }) {
      seo {
        metaDesc
        title
      }
      homePageHeaderSection {
        homeHeaderTitle
        homeHeaderDescription
      }
      companyVisionSection {
        companyVisionDescription
        companyVisionTitle
        companyVisionImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      companyMissionSection {
        companyMissionDescription
        companyMissionTitle
        companyMissionImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      technicalSection {
        technicalSectionDescription
        technicalSectionListItem
        technicalSectionListTitle
        technicalSectionSubtitle
        technicalSectionTitle
        technicalSectionImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`;
export default SalesForce;
