import { Link } from "gatsby";
import React from "react";

const CantactSalesForceContact = (props) =>{
    const{
        joinTitle,
        joinDescription,
        joinButton,
        linkSalesforce
    } = props
    return (
      <div className="join-section salesforce">
        <div className="full-container">
          <h3 className="join-section-title">{joinTitle}</h3>
          <p className="join-section-description salesforce-description">
            {joinDescription}
          </p>
          <Link className="join-section-button" to={linkSalesforce}>
            {joinButton}
          </Link>
        </div>
      </div>
    );
}
export default CantactSalesForceContact