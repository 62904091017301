import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import TitleIcone from '../../../static/assets/Features/TitleIcon.svg';
const TechnicalComponent = props => {
  const {
    technicalTitle,
    technicalSubtitle,
    technicalImage,
    technicalDescription,
    technicalListTitle,
    technicalListItem,
  } = props;

  return (
    <div className='technical-section'>
      <div className='full-container'>
        <div className='home-section-textIcone'>
          <TitleIcone className='home-section-titleIcone' />
          <h2 className='vision-section-title'>{technicalTitle}</h2>
        </div>
        <p className='technical-section-subtitle'>{technicalSubtitle}</p>
        <GatsbyImage
          alt='technicalImg'
          image={technicalImage}
          className=' technical-section-image'
        />
        <div className='row'>
          <div className='col-md-6'>
            <p className='technical-section-description'>
              {technicalDescription?.replace('Motomtech', 'UpTech Sh.P.K')}
            </p>
          </div>
          <div className='col-md-6'>
            <p className='technical-section-list-title'>{technicalListTitle}</p>
            <ul>
              <li
                className='technical-section-list-item'
                dangerouslySetInnerHTML={{ __html: technicalListItem }}
              ></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TechnicalComponent;
